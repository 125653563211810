@import '../../variables.scss';

.Footer { 
  padding: 1em 0;
  font-family: $font-family-metropolis;

  .DesktopLogoContainer {
    .DesktopIcon {
      display: none;
    }

    .MobileIcon {
      display: block;
      visibility: visible;
      max-width: 20em;
      margin: 0 auto;

      @media (max-width: $breakpoint-tablet) {
        max-width: 12em;
      }
    }
  }

  .NewsletterContainer {
    text-align: center;
    background-color: $primary;
    color: $white;
    padding: 1em 0.5em;

    @media (max-width: $breakpoint-tablet) {
      font-size: 75%;
    }

    span {
      @media (min-width: $breakpoint-tablet) {
        margin: 0 0.8em 0 0;
      }

      margin: 0 0.5em 1em;
    }

    .GoldText {
      color: $secondary;
    }

    .InputContainer {
      border: 1px solid $secondary;
      border-radius: 6px;

      input {
        outline: none;
        width: 100vw;
        max-width: 14em;
        background: none;
        border: none;
        color: inherit;
        padding: 0.5em 1em;
      }

      button {
        border: none;
        background: none;
        border-left: 1px solid $secondary;
        text-transform: uppercase;
        cursor: pointer;
        color: $white;
        color: inherit;
        padding: 0.5em 1em;

        &:hover {
          color: $secondary;
        }
      }
    }
  }

  .SocialIconsContainer {
    text-align: center;

    .Icons {
      display: inline-block;
      width: 1.6em;
      height: 1.6em;
      margin: 0 0.5em;
      cursor: pointer;
      color: $secondary;

      &:hover {
        color: $primary;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .FooterSignInContainer {
    text-align: center;

    .ModalOpenBtn {
      color: $secondary;

      &:hover {
        color: $primary;
      }
    }
  }

  .DesktopLogoContainer,
  .FooterSignInContainer,
  .NewsletterContainer {
    margin-bottom: 1.5em;
  } 
}